import * as React from "react"
import Layout from "../../components/layout"

import Seo from "../../components/seo"

import productbgImg from "../../images/productbg.png"

import Banner from "../../components/Banner/BannerMain"
import CoreModules from "../../components/CoreModules"
import AboutImgOne from "../../images/about1.png"
import AboutImgTwo from "../../images/about2.png"
import SectionTitle from "../../components/SectionTitle"
import DemoBookingSection from "../../components/DemoBookingSection"

import ProjectCalendarImg from "../../images/project-calendar.png"
import ProjectCreateStandardImg from "../../images/project-create-standard.png"
import AutoAssignImg from "../../images/auto-assign.png"
import NotificationImg from "../../images/notification.png"
import RealTimeImg from "../../images/real-time.png"
import StakeHolderImg from "../../images/stakeholder.png"

const modules = [
  {
    id: 1,
    title: "Standardize Project Creation",
    img: ProjectCreateStandardImg,
    paragraph:
      "Within minutes quickly and efficiently create projects that capture the nuances of your business and the project  ",
  },
  {
    id: 2,
    title: "Auto-Assign",
    img: AutoAssignImg,
    paragraph:
      "Automatically distribute Tasks, Deliverables and Milestones",
  },
  {
    id: 3,
    title: "Deliverable and Milestone Notification",
    img: NotificationImg,
    paragraph:
      "Never miss an important document or moment with our built-in notification system  ",
  },
  {
    id: 4,
    title: "Stakeholders Collaboration",
    img: StakeHolderImg,
    paragraph:
      "With everyone on the same system, all stakeholders can communicate and collaborate in real time ",
  },
  {
    id: 5,
    title: "Real-time Reporting",
    img: RealTimeImg,
    paragraph:
      "Eliminate outdated progress with reports with real-time project insights",
  },
  {
    id: 6,
    title: "Synchronized Calendar",
    img: ProjectCalendarImg,
    paragraph: "Have the entire project team sharing the same project calendar, thereby eliminating timing miscommunication",
  },
]

function Sherpa() {
  return (
    <Layout>
      <Seo title="Sherpa" />
      <section
        id="home"
        className="header overflow-hidden bg-emerald-50 relative  items-center flex w-full"
      >
        <div className="container px-4  max-w-6xl mx-auto items-center flex flex-wrap">
          <Banner
            title="Project. Execution. Perfected"
            titleTag="Sherpa"
            subTitle=""
            subTag=""
            img={productbgImg}
          />
        </div>
      </section>

      <section className="relative body-font w-full pt-10 pb-3 md:pb-20">
        <div className="container px-4 max-w-6xl mx-auto items-center flex flex-wrap">
          <SectionTitle
            titleHead="Core features"
            title="Features which together make a"
            spanTag="complete system"
            customClass="text-center md:w-full xl:w-full"
          />
          <CoreModules modules={modules} />
        </div>
      </section>

      <section className="relative body-font w-full pt-10 pb-3 md:pb-10 bg-themeGray">
        <div className="container px-4  max-w-6xl mx-auto items-center flex flex-wrap">
          <div className="flex flex-wrap items-center justify-between max-w-2xl lg:max-w-full mb-3 md:mb-12 pt-20 md:pt-40">
            <div
              className="w-full lg:w-1/2 lg:pr-16 relative order-1"
              data-aos="fade-up"
            >
              <img
                className="bg-white rounded shadow-lg rounded-lg absolute left-[0px] -top-[100px] animate-none md:animate-pulse 1s linear infinite"
                src={`${AboutImgTwo}`}
              />
              <img
                className="bg-white rounded shadow-lg rounded-lg "
                src={`${AboutImgOne}`}
              />
            </div>
            <div className="w-full lg:w-1/2 mt-10 md:mt-0 mb-2 lg:mb-0 order-2">
              <h4
                className="text-xl md:text-3xl font-medium mb-5"
                data-aos="fade-up"
              >
                Empower your team with the {" "}
                <span className="text-emerald-800 mr-1 inline-block">
                Sherpa project execution tool
                </span>
              </h4>
              <p className="leading-relaxed text-sm" data-aos="fade-up">
                With Sherpa Task module, the process of converting Task,
                Deliverables or Milestones of a Project is much easier,
                organised, and seamless.
              </p>
              <ul className="list-none mt-6" data-aos="fade-up">
                <li className="py-1">
                  <div className="flex items-center">
                    <span className="inline-block py-1 px-1 mr-1">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="text-emerald-800 w-5 h-5 flex-shrink-0 mr-1"
                        viewBox="0 0 24 24"
                      >
                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                        <path d="M22 4L12 14.01l-3-3"></path>
                      </svg>
                    </span>
                    <div>
                      <h4 className="text-sm">
                        Manage each phase of your Real Estate project.
                      </h4>
                    </div>
                  </div>
                </li>
                <li className="py-1">
                  <div className="flex items-center">
                    <span className="inline-block py-1 px-1 mr-1">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="text-emerald-800 w-5 h-5 flex-shrink-0 mr-1"
                        viewBox="0 0 24 24"
                      >
                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                        <path d="M22 4L12 14.01l-3-3"></path>
                      </svg>
                    </span>
                    <div>
                      <h4 className="text-sm">
                        Manage Resources of your Project for each phase
                      </h4>
                    </div>
                  </div>
                </li>
                <li className="py-1">
                  <div className="flex items-center">
                    <span className="inline-block py-1 px-1 mr-1">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="text-emerald-800 w-5 h-5 flex-shrink-0 mr-1"
                        viewBox="0 0 24 24"
                      >
                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                        <path d="M22 4L12 14.01l-3-3"></path>
                      </svg>
                    </span>
                    <div>
                      <h4 className="text-sm">Track Progress and status</h4>
                    </div>
                  </div>
                </li>
                <li className="py-1">
                  <div className="flex items-center">
                    <span className="inline-block py-1 px-1 mr-1">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="text-emerald-800 w-5 h-5 flex-shrink-0 mr-1"
                        viewBox="0 0 24 24"
                      >
                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                        <path d="M22 4L12 14.01l-3-3"></path>
                      </svg>
                    </span>
                    <div>
                      <h4 className="text-sm">
                        Get all you projects Insight into single view
                      </h4>
                    </div>
                  </div>
                </li>
                <li className="py-1">
                  <div className="flex items-center">
                    <span className="inline-block py-1 px-1 mr-1">
                      <svg
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        className="text-emerald-800 w-5 h-5 flex-shrink-0 mr-1"
                        viewBox="0 0 24 24"
                      >
                        <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                        <path d="M22 4L12 14.01l-3-3"></path>
                      </svg>
                    </span>
                    <div>
                      <h4 className="text-sm">Budget Management</h4>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <DemoBookingSection />
    </Layout>
  )
}

export default Sherpa
